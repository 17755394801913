<template>
  <div class="lecturers">
    <div class="container">
      <div class="small-intro">
        <intro-page :pageId="currentPageId"></intro-page>
      </div>
      <dynamic-filter @set-filter="setFilter" :filterValues="filterValues" default="all" :withBorders="false" />
      <div class="lecturer-list">
        <router-link class="lecturer-tile" :to="personLink(person)" v-for="(person, index) of filteredLecturers"
          :key="index">
          <div>
            <img class="icon-lecturer" src="@/assets/icon-course-lecturer-grey.svg" alt />
            <div class="lecturer-tile--name">
              {{ personName(person) }}
            </div>
            <div class="lecturer-tile--position">
              {{ person.attributes.field_position }}
            </div>
          </div>
          <img src="@/assets/arrow.svg" alt="arrow">
        </router-link>
      </div>

      <br>
      <br>
      <br>
      <br>
      <h1 class="light"><span class="green">Lecturers</span> of the past editions</h1>
      <br>

      <div class="lecturer-list past-list">
        <router-link class="lecturer-tile" :to="personLink(person)" v-for="(person, index) of pastLecturers"
          :key="index">
          <div>
            <img class="icon-lecturer" src="@/assets/icon-course-lecturer-grey.svg" alt />
            <div class="lecturer-tile--name">
              {{ personName(person) }}
            </div>
            <div class="lecturer-tile--position">
              {{ person.attributes.field_position }}
            </div>
          </div>
          <img src="@/assets/arrow.svg" alt="arrow">
        </router-link>
      </div>

    </div>
  </div>
</template>
<script>
import DynamicFilter from "@/components/DynamicFilter.vue";
import { slugify } from '@/libs/utils'
import IntroPage from '../components/IntroPage.vue'

export default {
  data() {
    return {
      currentPageId: "cce26fb9-3fb9-458c-aea2-d20f63f234e5",
      filterValues: [
        { label: "ALL", value: "all" },
        { label: "PART 1", value: "part-1" },
        { label: "PART 2", value: "part-2" },
      ],
      filter: 'all',
      lecturerWithPart: []
    };
  },
  components: { DynamicFilter, IntroPage },
  computed: {
    currentAcademicYear() {
      //console.log(this.$store.state.currentAcademicYear)
      return this.$store.state.currentAcademicYear
    },
    filteredLecturers() {
      let lecturers = this.lecturerWithPart.filter((item) => {
        //console.log(item.attributes.title, item.isNotPast)

        if (this.filter === 'part-1') {
          return item.isPart1 && item.isNotPast;
        } else if (this.filter === 'part-2') {
          return item.isPart2 && item.isNotPast;
        } else {
          return item.isNotPast
        }
      });

      return lecturers.sort((a, b) => {
        if (a.attributes.field_person_last_name < b.attributes.field_person_last_name) { return -1; }
        if (a.attributes.field_person_last_name > b.attributes.field_person_last_name) { return 1; }
        return 0;
      });
    },
    pastLecturers() {
      let lecturers = this.lecturerWithPart.filter((item) => {
        if (this.filter === 'part-1') {
          return item.isPart1 && !item.isNotPast;
        } else if (this.filter === 'part-2') {
          return item.isPart2 && !item.isNotPast;
        } else {
          return !item.isNotPast
        }
      });
      return lecturers.sort((a, b) => {
        if (a.attributes.field_person_last_name < b.attributes.field_person_last_name) { return -1; }
        if (a.attributes.field_person_last_name > b.attributes.field_person_last_name) { return 1; }
        return 0;
      });
    }
  },
  methods: {
    personLink(person) {
      let personSlug = slugify(this.personName(person));
      return `/person/${personSlug}-${person.attributes.drupal_internal__nid}`
    },
    personName(person) {
      return person.attributes.field_first_name + " " + person.attributes.field_person_last_name;
    },
    setFilter(val) {
      this.filter = val;
    },
    fetchCourses() {
      fetch(
        `${process.env.VUE_APP_ENDPOINT}/jsonapi/node/course?include=field_course_lecturer,field_academic_year`
      )
        .then((res) => res.json())
        .then((json) => {
          const included = json.included;
          this.courses = json.data.map((course) => {
            course.isPast = true;

            // aggiungo gli academic_year
            if (course.relationships.field_academic_year.data) {
              var academicYearsIds = course.relationships.field_academic_year.data.map((l) => l.id)

              course.academicYears = included
                .filter((item) => {
                  if (item.type === "taxonomy_term--academic_year") {
                    return academicYearsIds.includes(item.id);
                  }
                });

              course.academicYears.forEach(year => {
                if (year.attributes.name === this.currentAcademicYear) {
                  course.isPast = false;
                }
              })
            }

            // aggiungo i lecturer
            if (course.relationships.field_course_lecturer.data) {
              var lecturersIds = course.relationships.field_course_lecturer.data.map((l) => l.id)
              course.lecturers = included.filter((item) => {
                return lecturersIds.includes(item.id);
              });
            }

            /* accrocchio per stabilire quale lecturer ha corsi nella parte 1 o 2 */
            course.lecturers.forEach(newLecturer => {
              let needToPush = true;

              //tra quelli già aggiunti, se lo trovo aggiorno eventuali dati
              this.lecturerWithPart.forEach(lecturer => {
                if (lecturer.id === newLecturer.id) {
                  needToPush = false;

                  if (course.attributes.field_part === "2")
                    lecturer.isPart2 = true

                  if (course.attributes.field_part === "1")
                    lecturer.isPart1 = true

                  if (!course.isPast)
                    lecturer.isNotPast = true;
                }
              });

              //altimenti vuol dire che è nuovo e lo aggiungo con i suoi dati
              if (needToPush) {
                if (course.attributes.field_part === "2") newLecturer.isPart2 = true
                else newLecturer.isPart2 = false

                if (course.attributes.field_part === "1") newLecturer.isPart1 = true
                else newLecturer.isPart1 = false

                if (!course.isPast) newLecturer.isNotPast = true;
                else newLecturer.isNotPast = false;

                this.lecturerWithPart.push(newLecturer)
              }
            });

            return course;
          });
        });
    }
  },
  mounted() {
    this.fetchCourses();
  },
};
</script>

<style lang="scss">
.lecturers {
  margin-bottom: 60px;
}

.lecturer-tile {
  padding: 54px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  text-decoration: none;
  border: 1px solid #efefef;
  border-top-right-radius: 120px;
  border-bottom-right-radius: 120px;
  border-left: 0;

  .icon-lecturer {
    height: 30px;
    margin-top: -30px;
  }

  &:hover {
    text-decoration: none;
  }
}

.lecturer-tile--position {
  color: #666666;
}

.lecturer-tile--name {
  color: #011b4a;
  font-size: 30px;
  text-transform: uppercase;
  line-height: 1.1;
}

.lecturer-list {
  position: relative;
}

@media (min-width: 992px) {
  .lecturer-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .lecturer-tile {
      width: calc(50% - 30px);

      &:nth-child(4n),
      &:nth-child(4n-1) {
        border: 1px solid #1dc07e;
        border-top-left-radius: 120px;
        border-bottom-left-radius: 120px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      }
    }
  }
}

@media (max-width: 992px) {
  .lecturer-tile {
    padding: 54px 17px 54px 0px;
  }
}


/* .past-list {
  background-color: red;
} */
</style>